import React, {
  useState,
  useLayoutEffect,
  useRef,
  createRef,
  useEffect,
} from "react";
import styled, { keyframes } from "styled-components";
import Student from "../student/Student";
import SkillList from "../../models/SkillList";
import { skillsData } from "../../data/skills";
import Cost from "../cost/Cost";
import { Constants } from "../../data/Constants";
import { devices } from "../../devices";
import { RouteComponentProps } from "react-router-dom";
import { DropdownOptions } from "../../data/DropdownOption";

import firebase from "../../config/firebaseConfig";
import "firebase/database";
import "firebase/auth";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  width: 100vw;
  @media ${devices.mobile} {
    width: 100vw;
  }
`;

const SelectWrapper = styled.div`
  width: 45vw;
  padding: 0 8vw;

  @media ${devices.mobile} {
    width: 95vw;
    padding: 0;
    margin: auto;
    margin-bottom: 8vw;
  }
`;

const Select = styled.select`
  width: 45vw;
  height: 4.5vw;
  background: white;
  border: none;
  font-size: 1.5vw;
  font-family: "Inter";
  font-weight: 400;
  border-radius: 0.5vw;
  border: solid 1px black;
  outline: none;
  margin: none;
  margin-top: 1vw;
  margin-bottom: 3vw;
  padding: 0 1vw;
  background-color: white;

  option {
    color: black;
    background: white;
    white-space: pre;
    min-height: 20px;
    font-size: 1.3vw;
    font-family: "Inter";
    font-weight: 400;
    background-color: white;

    @media ${devices.mobile} {
      color: black;
      background: white;
      white-space: pre;
      font-size: 5vw;
      font-family: "Inter";
      font-weight: 400;
      background-color: white;
    }
  }

  @media ${devices.mobile} {
    font-size: 4.5vw;
    width: 90vw;
    height: 15vw;
  }
`;

const SingleTextField = styled.input`
  font-size: 1.5vw;
  font-family: "Inter";
  font-weight: 400;
  text-align: left;
  border-radius: 0.6vw;
  border: solid 1px black;
  outline: none;
  height: 5vw;
  width: 45vw;
  padding: 1vh;
  margin-top: 1vw;
  margin-bottom: 2vw;

  @media ${devices.mobile} {
    width: 90vw;
    font-size: 4.5vw;
    height: 15vw;
  }
`;

const ModalBody = styled.div`
  margin: auto;
  width: 60vw;
  background-color: white;
  border-radius: 1vw;

  @media ${devices.mobile} {
    width: 95vw;
    height: auto;
    border-radius: 3vw;
    margin: auto;
  }

  & > p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: 1.3vw;
    text-align: left;
    padding: 0 8vw;
    margin: 0;

    @media ${devices.mobile} {
      font-size: 4vw;
      line-height: 6vw;
      width: 90vw;
      padding: 0;
      margin: auto;
    }
  }

  & > h2 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: 2.3vw;
    text-align: left;
    font-weight: bold;
    padding: 0 8vw;
    margin: 4vw 0;

    @media ${devices.mobile} {
      font-size: 7vw;
      line-height: 8vw;
      margin-top: 8vw;
      margin-bottom: 8vw;
    }
  }
`;

const CloseButtonContainer = styled.div`
  height: 4vw;
  padding: 1vw;
  display: flex;
  overflow: hidden;
  justify-content: center;
  margin-bottom: 2vw;
  @media ${devices.mobile} {
    height: 18vw;
    padding: 3vw;
  }

  & > a {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: 1.2vw;
    text-align: left;
    font-weight: bold;
    letter-spacing: 0.02em;
    color: #a72c5a;
    cursor: pointer;
    outline: none;
    transition: transform 0.5s;

    &:active {
      color: #780d35;
    }
    &:hover {
      transform: scale(1.02);
    }
  }

  & > button {
    margin: auto 1vw;
    width: auto;
    height: 3vw;
    transition: transform 0.5s;

    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1vw;
    text-align: left;
    letter-spacing: 0.04em;
    color: white;
    background-color: #350a24;
    padding: 0 3vw;
    border: none;
    cursor: pointer;
    outline: none;

    @media ${devices.mobile} {
      font-size: 4.5vw;
      height: 12vw;
      padding: 0 8vw;
      border-radius: 1.8vw;
    }

    &:active {
      background-color: #780d35;
    }
    &:hover {
      transform: scale(1.05);
    }
  }
`;

interface Student {
  imageSrc: string;
  skills: SkillList;
}

interface RouterParams {
  id: string;
}

export default function FinishView(props: RouteComponentProps<RouterParams>) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props]);

  const [data, setData] = useState({
    jobTitle: "",
    numberOfEmployees: 0,
    ageRange: 0,
    genderIdentity: 0,
  });

  const saveToDatabase = () => {
    const db = firebase.database().ref();
    var updates: { [key: string]: object } = {};
    updates["/responses/" + props.match.params.id + "/participantData"] = data;
    db.update(updates, (error) => {
      if (error) {
        alert("It seems there is a network issue :(");
      }
      props.history.push("../../done");
    });
  };

  return (
    <Container>
      <ModalBody>
        <h2>Tell us about yourself</h2>
        <p>Your current job title</p>
        <SelectWrapper>
          <SingleTextField
            value={data.jobTitle}
            onChange={(e) => setData({ ...data, jobTitle: e.target.value })}
          ></SingleTextField>
        </SelectWrapper>

        <p>Number of employees at your current workplace</p>
        <SelectWrapper>
          <Select
            onChange={(e) =>
              setData({ ...data, numberOfEmployees: e.target.selectedIndex })
            }
          >
            {DropdownOptions.companySize.map((option) => {
              return <option>{option}</option>;
            })}
          </Select>
        </SelectWrapper>

        <p>Your age</p>
        <SelectWrapper>
          <Select
            onChange={(e) =>
              setData({ ...data, ageRange: e.target.selectedIndex })
            }
          >
            {DropdownOptions.age.map((option) => {
              return <option>{option}</option>;
            })}
          </Select>
        </SelectWrapper>

        <p>The gender that you mostly identify with</p>
        <SelectWrapper>
          <Select
            onChange={(e) =>
              setData({ ...data, genderIdentity: e.target.selectedIndex })
            }
          >
            {DropdownOptions.gender.map((option) => {
              return <option>{option}</option>;
            })}
          </Select>
        </SelectWrapper>

        <CloseButtonContainer>
          {<button onClick={() => saveToDatabase()}>SUBMIT</button>}
        </CloseButtonContainer>
      </ModalBody>
    </Container>
  );
}
