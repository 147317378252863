export const DropdownOptions = {
  age: ["select", "18-25", "26-40", "41-55", "56 or above"],
  gender: ["select", "female", "male", "not listed", "prefer not to answer"],
  companySize: [
    "select",
    "under 5",
    "6-10",
    "11-25",
    "26-50",
    "51-100",
    "100+",
  ],
};
