import React, { useEffect, useState } from "react";
import firebase from "../../config/firebaseConfig";
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  LineSeries,
  VerticalBarSeries,
  HorizontalBarSeries,
  FlexibleWidthXYPlot,
} from "react-vis";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: auto;
  font-size: 12px;
`;
export default function DataView() {
  const [gData, setgData] = useState<any[]>([]);
  useEffect(() => {
    readFromDatabase();
  }, []);

  let individualData: { [key: string]: number } = {};
  let graphData: any[] = [];
  const readFromDatabase = () => {
    var starCountRef = firebase.database().ref("responses/");
    starCountRef.once("value", function (snapshot) {
      //alert(JSON.stringify(snapshot));
      snapshot.forEach((child) => {
        processIndividual(child.child("individual/0"));
      });
      //alert(JSON.stringify(individualData));
      createGraphData();
      //alert(JSON.stringify(graphData));
    });
  };

  const processIndividual = (data: firebase.database.DataSnapshot) => {
    const concepts = data.child("concepts");
    const languages = data.child("languages");
    const softSkills = data.child("softSkills");
    const technologies = data.child("technologies");
    processChild(concepts);
    processChild(languages);
    processChild(softSkills);
    processChild(technologies);
  };

  const processChild = (data: firebase.database.DataSnapshot) => {
    let name: string;
    let selected: boolean;
    data.forEach((child) => {
      name = child.child("name").val();
      selected = false;
      if (JSON.stringify(child.child("selected")) == "true") {
        selected = true;
        if (individualData[name]) {
          individualData[name] = individualData[name] + 1;
        } else individualData[name] = 1;
      } else {
        if (!individualData[name]) {
          individualData[name] = 0;
        }
      }
    });
  };

  const createGraphData = () => {
    Object.entries(individualData).forEach((value) => {
      graphData.push({ x: value[0], y: value[1] });
    });
    setgData(graphData);
  };

  const MARGIN = {
    bottom: 250,
    top: 40,
  };

  if (!graphData) return null;

  return (
    <Wrapper>
      <XYPlot
        xType="ordinal"
        stackBy="y"
        width={window.innerWidth - 10}
        height={600}
        margin={MARGIN}
      >
        <VerticalBarSeries barWidth={0.9} data={gData} />
        <XAxis tickLabelAngle={-90} />
        <YAxis />
      </XYPlot>
    </Wrapper>
  );
}
