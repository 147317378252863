import React, {
  useState,
  useLayoutEffect,
  useRef,
  createRef,
  useEffect,
} from "react";
import styled, { keyframes } from "styled-components";
import Student from "../student/Student";
import SkillList from "../../models/SkillList";
import { skillsData } from "../../data/skills";
import Cost from "../cost/Cost";
import { Constants } from "../../data/Constants";
import { devices } from "../../devices";
import { RouteComponentProps } from "react-router-dom";

import firebase from "../../config/firebaseConfig";
import "firebase/database";
import "firebase/auth";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  width: 100vw;
  @media ${devices.mobile} {
    width: 90vw;
  }
`;

const ModalBody = styled.div`
  margin: auto;
  width: 85vw;
  background-color: white;
  border-radius: 1vw;

  @media ${devices.mobile} {
    width: 96vw;
    height: auto;
    border-radius: 3vw;
    margin: auto;
  }

  & > p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: 1.3vw;
    text-align: left;
    padding: 0 8vw;
    line-height: 2vw;

    @media ${devices.mobile} {
      font-size: 4.5vw;
      line-height: 7vw;
    }
  }

  & > h2 {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: 2.3vw;
    text-align: left;
    font-weight: bold;
    padding: 0 8vw;
    margin-top: 4vw;

    @media ${devices.mobile} {
      font-size: 7vw;
      line-height: 8vw;
      margin-top: 8vw;
    }
  }
`;

const XD = styled.div`
  width: 100vw;
  height: 8vw;
  background-color: black;
  margin-top: 5vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 2.5vw;

  @media ${devices.mobile} {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
    margin: auto;
    margin-top: 20vw;
    position: relative;
    padding: 8vw 0;
  }

  & > img {
    height: 7vw;
    width: auto;
    padding: 1vw;
    @media ${devices.mobile} {
      height: 28vw;
    }
  }

  & > p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: 1.1vw;
    text-align: left;
    padding: 0 5vw;
    line-height: 1.5vw;
    color: white;
    width: 90vw;

    @media ${devices.mobile} {
      font-size: 4.5vw;
      line-height: 7vw;
      width: 100vw;
      text-align: center;
    }

    & > a {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-size: 1.1vw;
      text-align: left;
      letter-spacing: 0.02em;
      color: white;
      cursor: pointer;
      outline: none;
      transition: transform 0.5s;
      @media ${devices.mobile} {
        font-size: 4.5vw;
        line-height: 5vw;
      }

      &:active {
        color: #780d35;
      }
      &:hover {
        transform: scale(1.02);
      }
    }
  }
`;

const CloseButtonContainer = styled.div`
  height: 4vw;
  padding: 1vw;
  display: flex;
  overflow: hidden;
  justify-content: center;
  margin-bottom: 2vw;
  @media ${devices.mobile} {
    height: 18vw;
    padding: 3vw;
  }

  & > a {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: 1.2vw;
    text-align: left;
    font-weight: bold;
    letter-spacing: 0.02em;
    color: #a72c5a;
    cursor: pointer;
    outline: none;
    transition: transform 0.5s;

    &:active {
      color: #780d35;
    }
    &:hover {
      transform: scale(1.02);
    }
  }

  & > button {
    margin: auto 1vw;
    width: auto;
    height: 3vw;
    transition: transform 0.5s;

    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1vw;
    text-align: left;
    letter-spacing: 0.04em;
    color: white;
    background-color: #350a24;
    padding: 0 3vw;
    border: none;
    cursor: pointer;
    outline: none;

    @media ${devices.mobile} {
      font-size: 4.5vw;
      height: 12vw;
      padding: 0 8vw;
    }

    &:active {
      background-color: #780d35;
    }
    &:hover {
      transform: scale(1.05);
    }
  }
`;

export default function StartView(props: RouteComponentProps) {
  var database;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <ModalBody>
        <h2>Hello!</h2>
        <p>
          Thanks for your interest in taking part in this study. <br />
          This study is aimed at deeply understanding how the Sri Lankan IT
          industry prioritizes skills expected of fresh software engineering
          graduates in the hiring process.
        </p>

        <p>
          This online tool will take you through 2 simple steps which will take
          no more than 10 mins to complete. Your participation is completely
          voluntary and you can leave this platform at any point by closing the
          browser tab.{" "}
          <strong>
            Neither personal identifiers nor information concerning your privacy
            will be recorded.
          </strong>
        </p>

        <p>
          Synthesized results of this study will be published through open
          access blog posts. You will receive the links to such blog posts
          through the same channels you were invited to take part in the study.
          We thank you in advance for your participation.
        </p>

        <CloseButtonContainer>
          {
            <button
              onClick={() => {
                const db = firebase.database().ref();
                var newKey = db.child("responses").push().key;
                props.history.push("/stepone/" + newKey);
                // alert(newKey);
                // var data = { a: "a" };
                // var updates: { [key: string]: object } = {};
                // updates["/responses/" + newKey + "/a"] = data;
                // db.update(updates, (error) => {
                //   if (error) {
                //     alert("It seems there is a network issue :(");
                //   }
                // });

                // //props.history.push("/stepone")

                // var data2 = { b: "b" };
                // var updates2: { [key: string]: object } = {};
                // updates2["/responses/" + newKey + "/b"] = data2;
                // db.update(updates2);
              }}
            >
              PROCEED
            </button>
          }
        </CloseButtonContainer>
      </ModalBody>
      <XD>
        <img src={require("./assets/XD.svg")}></img>
        <p>
          This research is designed and conducted by XD Studio, an experience
          design consultancy based in Colombo, Sri Lanka and Phoenix, Arizona.
          If you have any questions about this research, feel free to contact
          Piyum Fernando (
          <a href="mailto:piyum@thexdstudio.com">piyum@thexdstudio.com</a>
          ).
        </p>
      </XD>
    </Container>
  );
}
