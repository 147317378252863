import SkillList from "../models/SkillList";

export const skillsData: SkillList = {
  concepts: [
    {
      name: "OOP Concepts",
      cost: 200,
      keyPoints: ["Has a good theoretical understanding of OOP concepts "],
    },
    {
      name: "OOP Practice",
      cost: 400,
      keyPoints: [
        "Successfully applied OOP concepts to multiple coding projects",
      ],
    },
    {
      name: "Design Patterns Concepts",
      cost: 200,
      keyPoints: [
        "Has a good theoretical understanding of software design patterns ",
      ],
    },
    {
      name: "Design Patterns Practice",
      cost: 400,
      keyPoints: [
        "Successfully applied design patterns concepts to multiple coding projects",
      ],
    },
    {
      name: "Database Concepts",
      cost: 200,
      keyPoints: [
        "Has a good theoretical understanding of database concepts and their applications ",
      ],
    },
    {
      name: "Database Practice",
      cost: 400,
      keyPoints: [
        "Has a substantial hands-on experience in working with databases in multiple coding projects",
      ],
    },

    {
      name: "REST Concepts",
      cost: 200,
      keyPoints: ["Has a good theoretical understanding of REST web services "],
    },
    {
      name: "REST Practice",
      cost: 400,
      keyPoints: [
        "Has a substantial hands-on experience in implementing REST web services",
      ],
    },
    {
      name: "Cloud Computing Concepts",
      cost: 200,
      keyPoints: [
        "Has a good theoretical understanding of cloud computing concepts ",
      ],
    },
    {
      name: "Cloud Computing Practice",
      cost: 400,
      keyPoints: [
        "Has a substantial hands-on experience in implementing cloud based applications",
      ],
    },
    {
      name: "Data Structures and Algorithms Concepts",
      cost: 200,
      keyPoints: [
        "Has a good theoretical understanding of data structures and algorithms ",
      ],
    },
    {
      name: "Data Structures and Algorithms Practice",
      cost: 400,
      keyPoints: [
        "Has a substantial hands-on experience in applying data structures and algorithm concepts in coding projects",
      ],
    },
    {
      name: "Machine Learning Concepts",
      cost: 400,
      keyPoints: [
        "Has a substantial theoretical and mathematical understanding of Machine Learning concepts ",
      ],
    },
    {
      name: "Machine Learning Practice",
      cost: 400,
      keyPoints: [
        "Has a substantial hands-on experience in implementing machine learning applications",
      ],
    },
    {
      name: "AR/VR/MR Practice",
      cost: 400,
      keyPoints: [
        "Has a substantial hands-on experience in implementing augmented/virtual/mixed reality applications",
      ],
    },
  ],
  languages: [
    {
      name: "Java",
      cost: 400,
    },
    {
      name: "C/C++",
      cost: 400,
    },
    {
      name: "C#",
      cost: 400,
    },
    {
      name: "Visual Basic",
      cost: 400,
    },
    {
      name: "Java Script",
      cost: 400,
    },
    {
      name: "Type Script",
      cost: 400,
    },
    {
      name: "Python",
      cost: 400,
    },
    {
      name: "PHP",
      cost: 400,
    },
    {
      name: "Ruby",
      cost: 400,
    },
    {
      name: "Groovy",
      cost: 400,
    },
    {
      name: "Scala",
      cost: 400,
    },
    {
      name: "GO",
      cost: 400,
    },
    {
      name: "Swift",
      cost: 400,
    },
  ],
  technologies: [
    {
      name: "React/React Native",
      cost: 200,
    },
    {
      name: "Angular",
      cost: 200,
    },
    {
      name: "Vue",
      cost: 200,
    },
    {
      name: "Flutter",
      cost: 200,
    },
    {
      name: "Swing/Java FX",
      cost: 200,
    },
    {
      name: "Spring",
      cost: 200,
    },
    {
      name: ".net",
      cost: 200,
    },
    {
      name: "Express.js",
      cost: 200,
    },
    {
      name: "Django",
      cost: 200,
    },
    {
      name: "Laravel",
      cost: 200,
    },
    {
      name: "Symfony",
      cost: 200,
    },
    {
      name: "Rails",
      cost: 200,
    },
    {
      name: "Grails",
      cost: 200,
    },
    {
      name: "Android",
      cost: 200,
    },
    {
      name: "iOS",
      cost: 200,
    },
    {
      name: "Unity",
      cost: 200,
    },
  ],
  softSkills: [
    {
      name: "Problem Solving",
      cost: 400,
      keyPoints: [
        "Identifies and understands a problem clearly",
        "Thinks analytically and critically and come up with creative solutions",
      ],
    },
    {
      name: "Clear Written Communication",
      cost: 400,
      keyPoints: [
        "Writes clear emails",
        "Good at software documentation",
        "Writes blogs and participates in forums",
      ],
    },
    {
      name: "Clear Oral Communication",
      cost: 400,
      keyPoints: [
        "Does clear oral presentations",
        "Asks thoughtful questions",
        "Pitches ideas clearly and convincingly",
      ],
    },
    {
      name: "Leadership",
      cost: 400,
      keyPoints: [
        "Takes initiatives",
        "Volunteer for tasks",
        "Acts with confidence",
      ],
    },
    {
      name: "Cooperative Teamwork",
      cost: 400,
      keyPoints: ["Resolves conflicts", "Shares knowledge", "Backs team mates"],
    },
    // {
    //   name: "Integrity and Respect",
    //   cost: 400,
    //   keyPoints: [
    //     "Treats others fairly",
    //     "Trusted by peers",
    //     "Operates ethically",
    //   ],
    // },
    {
      name: "Decision Making",
      cost: 400,
      keyPoints: [
        "Evaluate alternatives",
        "Takes responsibility for your own decisions",
      ],
    },

    {
      name: "Entrepreneurial Mindset",
      cost: 400,
      keyPoints: [
        "Understands the software business",
        "Knows how to sell a product",
      ],
    },
    {
      name: "Client Focus",
      cost: 400,
      keyPoints: [
        "Empathizes and identifies client needs",
        "Makes the client feel valued",
      ],
    },
    {
      name: "Lifelong Learning",
      cost: 400,
      keyPoints: [
        "Aspires to learn new skills",
        "Always looks for learning opportunities",
      ],
    },
  ],
};
