// Your web app's Firebase configuration
import * as firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyBQ9l26JIPpAW24THp27qTlnIOmSs0j_Ng",
  authDomain: "xdresearch-spark.firebaseapp.com",
  databaseURL: "https://xdresearch-spark.firebaseio.com",
  projectId: "xdresearch-spark",
  storageBucket: "xdresearch-spark.appspot.com",
  messagingSenderId: "837476785216",
  appId: "1:837476785216:web:da9a6ffc058e9e87bc93a5",
  measurementId: "G-SC24J1E8WP",
};

export default !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : firebase.app();
