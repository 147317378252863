import React from "react";
import styled from "styled-components";
import { devices } from "../../devices";

const Container = styled.div<{ large?: boolean; disable?: boolean }>`
  width: ${(props) => (props.large ? "5.8vw" : "4.5vw")};
  display: flex;
  align-items: center;

  font-family: "Oxygen", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => (props.large ? "1.8vw" : "1.3vw")};
  text-align: left;
  letter-spacing: 0.04em;
  justify-content: center;
  opacity: ${(props) => (props.disable ? 0.2 : 1)};

  @media ${devices.mobile} {
    font-size: ${(props) => (props.large ? "5vw" : "4.5vw")};
  }

  & > img {
    width: ${(props) => (props.large ? "1.8vw" : "1.5vw")};
    height: ${(props) => (props.large ? "1.8vw" : "1.5vw")};
    margin-right: 0.3vw;

    @media ${devices.mobile} {
      width: ${(props) => (props.large ? "5vw" : "4vw")};
      height: ${(props) => (props.large ? "5vw" : "4vw")};
      margin-right: 2vw;
    }
  }
`;

export default function Cost(props: {
  cost: number;
  large?: boolean;
  disable?: boolean;
}) {
  return (
    <Container large={props.large} disable={props.disable}>
      <img src={require("./assets/coin_icon.svg")}></img>
      <p>{props.cost}</p>
    </Container>
  );
}
