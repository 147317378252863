import React, {
  useState,
  useLayoutEffect,
  useRef,
  createRef,
  useEffect,
} from "react";
import styled, { keyframes } from "styled-components";
import Student from "../student/Student";
import SkillList from "../../models/SkillList";
import { skillsData } from "../../data/skills";
import Cost from "../cost/Cost";
import { Constants } from "../../data/Constants";
import { devices } from "../../devices";
import { RouteComponentProps } from "react-router-dom";
import firebase from "../../config/firebaseConfig";
import "firebase/database";
import "firebase/auth";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  width: 100vw;
  margin-bottom: 5vw;
  @media ${devices.mobile} {
    width: 90vw;
  }
`;

const SummeryBar = styled.div`
  width: 100vw;
  height: 5vw;
  padding: 0.4vw 1vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-content: center;

  @media ${devices.mobile} {
    height: 25vw;
  }
`;

const AvatarCostContainer = styled.div`
  display: flex;
  height: 5vw;
  width: 75vw;
  justify-content: flex-start;
  align-items: center;

  @media ${devices.mobile} {
    height: 25vw;
    width: 50vw;
  }

  & > img {
    animation: ${fadeIn} 1s;
    width: 4vw;
    height: 4vw;
    margin-right: 1vw;

    @media ${devices.mobile} {
      width: 20vw;
      height: 20vw;
      margin-right: 5vw;
    }
  }

  & > div {
    animation: ${fadeIn} 1s;
  }
`;
const StudentContainer = styled.div`
  display: flex;
  width: 100vw;
  justify-content: space-around;
  margin-top: 12vw;

  @media ${devices.mobile} {
    flex-wrap: wrap;
    margin-top: 40vw;
  }
`;

const TotalContainer = styled.div`
  display: flex;
  width: 20vw;
  height: 5vw;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 5vw;

  font-family: "Oxygen", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 2.5vw;
  text-align: left;
  letter-spacing: 0.04em;

  @media ${devices.mobile} {
    width: 40vw;
    height: 25vw;
    font-size: 6vw;
  }

  & > img {
    width: 2.5vw;
    height: 2.5vw;
    margin: 0 1vw;
    @media ${devices.mobile} {
      width: 8vw;
      height: 8vw;
    }
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
  display: flex;
  background-color: rgb(255, 255, 255);
  @media ${devices.mobile} {
    min-height: 100%;
    height: auto;
  }
`;

const ModalBody = styled.div`
  margin: auto;
  width: 75vw;
  background-color: white;
  border: 1px solid grey;
  border-radius: 1vw;

  @media ${devices.mobile} {
    width: 96vw;
    height: auto;
    border-radius: 3vw;
    margin: auto;
  }

  & > p {
    font-family: "Oxygen", sans-serif;
    font-style: normal;
    font-size: 1.3vw;
    text-align: left;
    padding: 0 8vw;
    line-height: 2vw;

    @media ${devices.mobile} {
      font-size: 4.5vw;
      line-height: 6vw;
    }
  }

  & > h2 {
    font-family: "Oxygen", sans-serif;
    font-style: normal;
    font-size: 2.3vw;
    text-align: left;
    font-weight: bold;
    padding: 0 8vw;
    margin-top: 4vw;

    @media ${devices.mobile} {
      font-size: 7vw;
      line-height: 8vw;
      margin-top: 8vw;
    }
  }
`;

const CloseButtonContainer = styled.div`
  height: 4vw;
  padding: 1vw;
  display: flex;
  overflow: hidden;
  justify-content: flex-end;
  margin-bottom: 2vw;
  @media ${devices.mobile} {
    height: 18vw;
    padding: 3vw;
  }

  & > a {
    font-family: "Oxygen", sans-serif;
    font-style: normal;
    font-size: 1.2vw;
    text-align: left;
    font-weight: bold;
    letter-spacing: 0.02em;
    color: #a72c5a;
    cursor: pointer;
    outline: none;
    transition: transform 0.5s;

    &:active {
      color: #780d35;
    }
    &:hover {
      transform: scale(1.02);
    }
  }

  & > button {
    margin: auto 1vw;
    width: auto;
    height: 3vw;
    transition: transform 0.5s;

    font-family: "Oxygen", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1vw;
    text-align: left;
    letter-spacing: 0.04em;
    color: white;
    background-color: #a72c5a;
    padding: 0 3vw;
    border: none;
    border-radius: 0.3vw;
    cursor: pointer;
    outline: none;

    @media ${devices.mobile} {
      font-size: 4.5vw;
      height: 12vw;
      padding: 0 8vw;
      border-radius: 1.8vw;
    }

    &:active {
      background-color: #780d35;
    }
    &:hover {
      transform: scale(1.05);
    }
  }
`;

const InstructionButton = styled.div`
  width: 3vw;
  height: 3vw;
  transition: transform 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Oxygen", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5vw;
  text-align: left;
  letter-spacing: 0.04em;
  color: white;
  background-color: #a72c5a;
  border: none;
  border-radius: 100vw;
  cursor: pointer;
  outline: none;
  margin: 0vw 1vw;

  @media ${devices.mobile} {
    font-size: 4.5vw;
    height: 12vw;
    width: 12vw;
    border-radius: 30vw;
    margin: 0vw 4vw;
  }

  &:active {
    background-color: #780d35;
  }
  &:hover {
    transform: scale(1.05);
  }
`;

const BottomButtonContainer = styled.div`
  height: 4vw;
  width: 95vw;
  padding: 0 2vw;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 1vw;
  right: 0;

  @media ${devices.mobile} {
    position: relative;
    margin: 20vw 0;
  }

  & > button {
    width: auto;
    height: 3vw;
    transition: transform 0.5s;

    font-family: "Oxygen", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1vw;
    text-align: left;
    letter-spacing: 0.04em;
    color: white;
    background-color: #a72c5a;
    padding: 0 3vw;
    border: none;
    border-radius: 0.3vw;
    cursor: pointer;
    outline: none;
    margin: 0vw 1vw;

    @media ${devices.mobile} {
      font-size: 4.5vw;
      height: 12vw;
      padding: 0 8vw;
      border-radius: 1.8vw;
    }

    &:active {
      background-color: #780d35;
    }
    &:hover {
      transform: scale(1.05);
    }
  }
`;

interface Student {
  imageSrc: string;
  skills: SkillList;
}

interface RouterParams {
  id: string;
}

export default function InputView(props: RouteComponentProps<RouterParams>) {
  const [students, setStudents] = useState<Student[]>([
    {
      imageSrc: require("./assets/e.svg"),
      skills: JSON.parse(JSON.stringify(skillsData)),
    },
    {
      imageSrc: require("./assets/c.svg"),
      skills: JSON.parse(JSON.stringify(skillsData)),
    },
    {
      imageSrc: require("./assets/d.svg"),
      skills: JSON.parse(JSON.stringify(skillsData)),
    },
    {
      imageSrc: require("./assets/a.svg"),
      skills: JSON.parse(JSON.stringify(skillsData)),
    },
  ]);

  const [showHelp, setShowHelp] = useState(true);
  const [selectedAvatar, setSelectedAvatar] = useState<number>(-1);
  const [showSummeryAvatar, setShowSummeryAvatar] = useState(false);
  const element = createRef<HTMLDivElement>();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.innerWidth < 800) {
        setShowSummeryAvatar(true);
      } else if (element && element.current) {
        setShowSummeryAvatar(element.current.getBoundingClientRect().top < 0);
      }
    });
  });

  const calculateTotalCost = () => {
    let cost: number = 0;
    students.map((student, index) => {
      student.skills.softSkills.map((skill, index) => {
        if (skill.selected) {
          cost += skill.cost;
        }
      });
      student.skills.concepts.map((skill, index) => {
        if (skill.selected) {
          cost += skill.cost;
        }
      });
      student.skills.languages.map((skill, index) => {
        if (skill.selected) {
          cost += skill.cost;
        }
      });
      student.skills.technologies.map((skill, index) => {
        if (skill.selected) {
          cost += skill.cost;
        }
      });
    });
    return cost;
  };

  const calculateStudentCost = (index: number) => {
    let cost: number = 0;

    const student = students[index];
    student.skills.softSkills.map((skill, index) => {
      if (skill.selected) {
        cost += skill.cost;
      }
    });
    student.skills.concepts.map((skill, index) => {
      if (skill.selected) {
        cost += skill.cost;
      }
    });
    student.skills.languages.map((skill, index) => {
      if (skill.selected) {
        cost += skill.cost;
      }
    });
    student.skills.technologies.map((skill, index) => {
      if (skill.selected) {
        cost += skill.cost;
      }
    });
    return cost;
  };

  const saveToDatabase = () => {
    const selectedSkills: { [key: number]: SkillList } = {};
    students.map((student, index) => {
      selectedSkills[index] = student.skills;
    });
    const db = firebase.database().ref();
    var updates: { [key: string]: object } = {};
    updates["/responses/" + props.match.params.id + "/team"] = selectedSkills;
    db.update(updates, (error) => {
      if (error) {
        alert("It seems there is a network issue :(");
      }
      props.history.push("../data/" + props.match.params.id);
    });
  };

  return (
    <Container>
      {showHelp && (
        <Modal>
          <ModalBody>
            <h2>STEP 2</h2>
            <p>
              Now assume that you want to hire a team of{" "}
              <strong>upto 4 fresh graduates</strong>. You have a monthly budget
              of 4000 star dollars for the whole team.
            </p>

            <p>
              Similar to step 1, you can pick and choose the skills that you
              expect from the new recruits. However now you have 2 constraints:
              the total cost of the{" "}
              <strong>team cannot exceed 4000 star dollars</strong> and{" "}
              <strong>
                the cost of a single team member cannot exceed 2000 star dollars
              </strong>
              .
            </p>

            <p>What skills would you select for your team?</p>

            <CloseButtonContainer>
              {<button onClick={() => setShowHelp(false)}>PICK SKILLS</button>}
            </CloseButtonContainer>
          </ModalBody>
        </Modal>
      )}
      {!showHelp && (
        <React.Fragment>
          <SummeryBar>
            <AvatarCostContainer>
              {selectedAvatar >= 0 && showSummeryAvatar && (
                <img src={students[selectedAvatar].imageSrc}></img>
              )}
              {selectedAvatar >= 0 && showSummeryAvatar && (
                <Cost cost={calculateStudentCost(selectedAvatar)}></Cost>
              )}
            </AvatarCostContainer>
            <TotalContainer>
              <img src={require("./assets/coin_icon.svg")}></img>
              <p>{Constants.teambudget - calculateTotalCost()}</p>
            </TotalContainer>
          </SummeryBar>

          <StudentContainer ref={element}>
            {students.map((student, index) => {
              return (
                <Student
                  imageSrc={student.imageSrc}
                  skills={student.skills}
                  index={index}
                  total={calculateTotalCost()}
                  showSkillDialog={index === selectedAvatar}
                  disable={selectedAvatar >= 0 && index != selectedAvatar}
                  onAvatarClick={(index) => {
                    selectedAvatar < 0 && setSelectedAvatar(index);
                    window.scrollTo(0, 0);
                  }}
                  onCloseTrigger={() => setSelectedAvatar(-1)}
                  onSkillChange={(skills, index) => {
                    let studentsTemp = [...students];
                    studentsTemp[index].skills = skills;
                    setStudents(studentsTemp);
                  }}
                ></Student>
              );
            })}
          </StudentContainer>

          <BottomButtonContainer>
            {selectedAvatar < 0 && (
              <InstructionButton onClick={() => setShowHelp(true)}>
                ?
              </InstructionButton>
            )}
            {selectedAvatar < 0 && calculateTotalCost() > 0 && (
              <button onClick={() => saveToDatabase()}>SUBMIT</button>
            )}
          </BottomButtonContainer>
        </React.Fragment>
      )}
    </Container>
  );
}
