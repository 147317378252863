import React, { useState, useLayoutEffect, useEffect } from "react";
import SkillData from "../../models/Skill";
import styled from "styled-components";
import Cost from "../cost/Cost";
import SkillList from "../../models/SkillList";
import SkillDialog from "../skillDialog/SkillDialog";
import { devices } from "../../devices";

const MainContainer = styled.div<{ disable?: boolean }>`
  width: 16vw;
  height: 60vh;
  opacity: ${(props) => (props.disable ? "20%" : "100%")};
  @media ${devices.mobile} {
    width: 90vw;
    height: auto;
    border-radius: 3vw;
    margin: 8vw auto;
    /* border: 1px solid #a72c5a;
    box-shadow: 7px 7px 17px rgba(0, 0, 0, 0.25);
    border-radius: 18px; */
  }
`;

const Container = styled.div<{ disable?: boolean; selected?: boolean }>`
  width: 18vw;
  height: 18vw;
  padding-top: 1.2vw;
  background: #ffffff;
  border: 1px solid #a72c5a;
  box-shadow: 7px ${(props) => (props.selected ? 20 : 7)}px 17px
    rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  transition: transform 0.5s, box-shadow 0.5s;
  transform: scale(${(props) => (props.selected ? 1.03 : 1)});
  cursor: pointer;

  &:hover {
    transform: scale(${(props) => (props.disable ? 1 : 1.03)});
    box-shadow: 7px 20px 17px rgba(0, 0, 0, 0.25);
  }

  @media ${devices.mobile} {
    padding-top: 2vw;
    width: 90vw;
    height: 55vw;
  }

  & > img {
    margin: auto;
    height: 10vw;
    @media ${devices.mobile} {
      height: 30vw;
    }
  }

  & > div {
    margin: auto;
  }
`;

const SkillContainer = styled.div`
  margin-top: 2vw;

  & > p {
    text-align: center;
    font-family: "Oxygen", sans-serif;
    font-style: normal;
    font-size: 1.1vw;
    letter-spacing: 0.02em;

    @media ${devices.mobile} {
      font-size: 5vw;
      margin: 1.5vw;
    }
  }
`;

export default function Student(props: {
  imageSrc: string;
  skills: SkillList;
  total: number;
  index: number;
  showSkillDialog: boolean;
  disable?: boolean;
  onAvatarClick: (index: number) => void;
  onCloseTrigger: () => void;
  onSkillChange: (skills: SkillList, index: number) => void;
}) {
  // const [skills, setSkills] = useState(props.skills);
  const [number, setNumber] = useState(0);

  useLayoutEffect(() => {
    window.addEventListener("scroll", () => {
      setNumber(window.scrollX);
    });
  });

  const calculateCost = () => {
    if (props.skills) {
      let cost: number = 0;
      props.skills.softSkills.map((skill, index) => {
        if (skill.selected) {
          cost += skill.cost;
        }
      });
      props.skills.concepts.map((skill, index) => {
        if (skill.selected) {
          cost += skill.cost;
        }
      });
      props.skills.languages.map((skill, index) => {
        if (skill.selected) {
          cost += skill.cost;
        }
      });
      props.skills.technologies.map((skill, index) => {
        if (skill.selected) {
          cost += skill.cost;
        }
      });
      return cost;
    }
    return 0;
  };

  return (
    <MainContainer disable={props.disable}>
      <Container
        disable={props.disable}
        selected={props.showSkillDialog}
        onClick={() =>
          props.showSkillDialog
            ? props.onCloseTrigger()
            : props.onAvatarClick(props.index)
        }
      >
        <img src={props.imageSrc}></img>
        <Cost large={true} cost={calculateCost()}></Cost>
      </Container>
      <SkillContainer>
        {props.skills.softSkills.map((skill, index) => {
          if (skill.selected) {
            return <p>{skill.name}</p>;
          }
          return null;
        })}

        {props.skills.concepts.map((skill, index) => {
          if (skill.selected) {
            return <p>{skill.name}</p>;
          }
          return null;
        })}

        {props.skills.languages.map((skill, index) => {
          if (skill.selected) {
            return <p>{skill.name}</p>;
          }
          return null;
        })}

        {props.skills.technologies.map((skill, index) => {
          if (skill.selected) {
            return <p>{skill.name}</p>;
          }
          return null;
        })}
      </SkillContainer>
      {props.showSkillDialog && (
        <SkillDialog
          skills={props.skills}
          total={props.total}
          salary={calculateCost()}
          onCloseTrigger={() => props.onCloseTrigger()}
          onSelect={(skills) => {
            props.onSkillChange(skills, props.index);
          }}
        ></SkillDialog>
      )}
    </MainContainer>
  );
}
