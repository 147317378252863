import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Skill from "./components/skill/Skill";
import InputView from "./components/views/InputView";
import SkillDialog from "./components/skillDialog/SkillDialog";
import StepOneView from "./components/views/StepOneView";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import StartView from "./components/views/StartView";
import FinishView from "./components/views/FinishView";
import DoneView from "./components/views/DoneView";
import DataView from "./components/views/DataView";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={StartView} />
          <Route exact path="/stepone/:id" component={StepOneView} />
          <Route exact path="/steptwo/:id" component={InputView} />
          <Route exact path="/data/:id" component={FinishView} />
          <Route exact path="/done" component={DoneView} />
          <Route exact path="/results" component={DataView} />
        </Switch>
      </BrowserRouter>
      {/* <InputView></InputView> */}
    </div>
  );
}

export default App;
