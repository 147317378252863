import React, { useState } from "react";
import styled from "styled-components";
import Skill from "../skill/Skill";
import SkillList from "../../models/SkillList";
import { skillsData } from "../../data/skills";
import { devices } from "../../devices";

const Container = styled.div`
  width: 95vw;
  position: absolute;
  background-color: white;
  border: 1px solid lightpink;
  border-radius: 1vw;
  top: 32vw;
  left: 2.5vw;
  z-index: 100;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding-top: 1.2vw;
`;

const SkillContainer = styled.div`
  width: 85vw;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  margin-bottom: 5vw;
`;

const CloseButtonContainer = styled.div`
  height: 4vw;
  padding: 1vw;
  display: flex;
  overflow: hidden;
  justify-content: flex-end;
  margin-bottom: 2vw;
  @media ${devices.mobile} {
    height: 18vw;
    padding: 3vw;
  }

  & > a {
    font-family: "Oxygen", sans-serif;
    font-style: normal;
    font-size: 1.2vw;
    text-align: left;
    font-weight: bold;
    letter-spacing: 0.02em;
    color: #a72c5a;
    cursor: pointer;
    outline: none;
    transition: transform 0.5s;

    @media ${devices.mobile} {
      font-size: 4.5vw;
    }

    &:active {
      color: #780d35;
    }
    &:hover {
      transform: scale(1.02);
    }
  }

  & > button {
    margin: auto 1vw;
    width: auto;
    height: 3vw;
    transition: transform 0.5s;

    font-family: "Oxygen", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1vw;
    text-align: left;
    letter-spacing: 0.04em;
    color: white;
    background-color: #a72c5a;
    padding: 0 3vw;
    border: none;
    border-radius: 0.3vw;
    cursor: pointer;
    outline: none;

    @media ${devices.mobile} {
      font-size: 4.5vw;
      height: 12vw;
      padding: 0 8vw;
      border-radius: 1.8vw;
    }

    &:active {
      background-color: #780d35;
    }
    &:hover {
      transform: scale(1.05);
    }
  }
`;

const Tooltip = styled.div<{ x: number; y: number }>`
  width: 20vw;
  background-color: grey;
  border-radius: 0.2vw;
  position: fixed;
  top: ${(props) => {
    return props.y + "px";
  }};
  left: ${(props) => props.x + "px"};
  /* top:100px;
  left:100px; */
  z-index: 200;
`;

const SkillCatagory = styled.p`
  font-family: "Oxygen", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.8vw;
  text-align: left;
  line-height: 2vw;
  color: black;
  margin: auto;
  width: 95vw;
  margin-top: 4vw;
  margin-bottom: 2vw;

  @media ${devices.mobile} {
    font-size: 5.5vw;
    line-height: 8vw;
    margin-top: 7vw;
    margin-bottom: 3vw;
  }
`;

export default function SkillDialog(props: {
  skills: SkillList;
  onSelect: (skills: SkillList) => void;
  onCloseTrigger: () => void;
  total: number;
  salary: number;
}) {
  // const [skills, setSkills] = useState(props.skills);
  const [tooltipData, setTooltipData] = useState<{
    points: string[];
    x: number;
    y: number;
  } | null>(null);

  return (
    <Container>
      {/* <CloseButtonContainer>
        {
          <a
            onClick={() =>
              props.onSelect(JSON.parse(JSON.stringify(skillsData)))
            }
          >
            CLEAR ALL
          </a>
        }
      </CloseButtonContainer> */}
      <SkillContainer>
        {tooltipData && (
          <Tooltip x={tooltipData.x} y={tooltipData.y}>
            {tooltipData.points.map((point, index) => {
              return <p>{point}</p>;
            })}
          </Tooltip>
        )}
        <SkillCatagory>Professional Soft Skills</SkillCatagory>
        {props.skills.softSkills.map((skill, index) => {
          return (
            <Skill
              data={skill}
              index={index}
              total={props.total}
              salary={props.salary}
              updateToolTip={(
                data: { points: string[]; x: number; y: number } | null
              ) => {
                setTooltipData(data);
              }}
              onSelect={(index) => {
                props.skills.softSkills[index].selected = !props.skills
                  .softSkills[index].selected;
                props.onSelect(props.skills);
              }}
            ></Skill>
          );
        })}
      </SkillContainer>
      <SkillContainer>
        <SkillCatagory>Theory and Practice</SkillCatagory>
        {props.skills.concepts.map((skill, index) => {
          return (
            <Skill
              data={skill}
              index={index}
              total={props.total}
              salary={props.salary}
              updateToolTip={(
                data: { points: string[]; x: number; y: number } | null
              ) => {
                setTooltipData(data);
              }}
              onSelect={(index) => {
                props.skills.concepts[index].selected = !props.skills.concepts[
                  index
                ].selected;
                props.onSelect(props.skills);
              }}
            ></Skill>
          );
        })}
      </SkillContainer>
      <SkillContainer>
        <SkillCatagory>Programming Languages</SkillCatagory>
        {props.skills.languages.map((skill, index) => {
          return (
            <Skill
              data={skill}
              index={index}
              total={props.total}
              salary={props.salary}
              updateToolTip={(
                data: { points: string[]; x: number; y: number } | null
              ) => {
                setTooltipData(data);
              }}
              onSelect={(index) => {
                props.skills.languages[index].selected = !props.skills
                  .languages[index].selected;
                props.onSelect(props.skills);
              }}
            ></Skill>
          );
        })}
      </SkillContainer>
      <SkillContainer>
        <SkillCatagory>Libraries, Frameworks and Platforms</SkillCatagory>
        {props.skills.technologies.map((skill, index) => {
          return (
            <Skill
              data={skill}
              index={index}
              total={props.total}
              salary={props.salary}
              updateToolTip={(
                data: { points: string[]; x: number; y: number } | null
              ) => {
                setTooltipData(data);
              }}
              onSelect={(index) => {
                props.skills.technologies[index].selected = !props.skills
                  .technologies[index].selected;
                props.onSelect(props.skills);
              }}
            ></Skill>
          );
        })}
      </SkillContainer>
      <CloseButtonContainer>
        <button onClick={() => props.onCloseTrigger()}>DONE</button>
      </CloseButtonContainer>
    </Container>
  );
}
