import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import SkillData from "../../models/Skill";
import Cost from "../cost/Cost";
import { Constants } from "../../data/Constants";
import { devices } from "../../devices";

const Container = styled.div<{ selected?: boolean; disabled?: boolean }>`
  width: 19vw;
  height: 10vh;
  display: flex;
  border-radius: 1vw;
  border: ${(props) => (props.selected ? "2px" : "1px")} solid
    ${(props) => (props.selected ? "#FEB422" : "#d7d7d7")};
  background-color: ${(props) => (props.selected ? "#FFFFFF" : "#FFFFFF")};

  justify-content: space-around;
  align-items: center;
  margin: 0.5vw;
  cursor: ${(props) =>
    props.disabled && !props.selected ? "not-allowed" : "pointer"};
  transition: border-width 0.3s;
  position: relative;

  & > p {
    opacity: ${(props) => (props.disabled && !props.selected ? 0.5 : 1)};
  }

  &:hover {
    border-width: ${(props) =>
      props.disabled && !props.selected ? "1px" : "3px"};
  }

  @media ${devices.mobile} {
    width: 90vw;
    height: 40vw;
    margin: 3vw auto;
    border-radius: 3vw;
    border-width: 0.8vw;
    &:hover {
      border-width: 0.8vw;
    }
  }
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const MobileOnly = styled.div`
  display: none;
  @media ${devices.mobile} {
    display: flex;
    flex-direction: column;
    & > p {
      font-family: "Oxygen", sans-serif;
      font-style: normal;
      font-size: 3.2vw;
      line-height: 131.31%;
      width: 50vw;
      text-align: left;
      letter-spacing: 0.02em;
      margin: 0.8vw 0;
    }
  }
`;

const Name = styled.p`
  font-family: "Oxygen", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.1vw;
  line-height: 131.31%;
  width: 12vw;
  text-align: left;
  letter-spacing: 0.02em;

  @media ${devices.mobile} {
    font-size: 4.2vw;
    width: 50vw;
    margin: 2vw 0;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Tooltip = styled.div<{ visibility?: boolean }>`
  width: 20vw;
  background-color: #ededed;
  border-radius: 0.2vw;
  position: absolute;
  top: 4vw;
  left: 5vw;
  z-index: 500;
  animation: ${fadeIn} 1s;
  visibility: ${(props) => (props.visibility ? "visible" : "hidden")};
  padding: 0.8vw;

  @media ${devices.mobile} {
    visibility: hidden;
  }

  & > p {
    font-family: "Oxygen", sans-serif;
    font-style: normal;
    font-size: 1.1vw;
    line-height: 131.31%;
    text-align: left;
    letter-spacing: 0.02em;
  }
`;
export default function Skill(props: {
  data: SkillData;
  index: number;
  updateToolTip: (
    data: { points: string[]; x: number; y: number } | null
  ) => void;
  onSelect: (index: number) => void;
  total: number;
  salary: number;
}) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [timerID, setTimerId] = useState(-1);

  const isDisabled = () => {
    return (
      props.data.cost > Constants.teambudget - props.total ||
      props.data.cost > Constants.maxSalary - props.salary
    );
  };
  return (
    <Container
      onMouseEnter={(e) => {
        // setShowTooltip(true);
        setTimerId(setTimeout(() => setShowTooltip(true), 300));
      }}
      onMouseLeave={() => {
        clearTimeout(timerID);
        setShowTooltip(false);
      }}
      selected={props.data.selected}
      disabled={isDisabled()}
      onClick={() => {
        if (!isDisabled() || props.data.selected) {
          props.onSelect(props.index);
        }
      }}
    >
      <TextContainer>
        <Name>{props.data.name}</Name>
        <MobileOnly>
          {props.data.keyPoints &&
            props.data.keyPoints.map((point, index) => {
              return <p>{point}</p>;
            })}
        </MobileOnly>
      </TextContainer>
      <Cost cost={props.data.cost} disable={isDisabled()}></Cost>
      {props.data.keyPoints && (
        <Tooltip visibility={showTooltip}>
          {props.data.keyPoints.map((point, index) => {
            return <p>{point}</p>;
          })}
        </Tooltip>
      )}
    </Container>
  );
}
